export default defineAppConfig({
  title: "Loyalty",
  theme: {
    colors: {
      primary: "brand",
    },
  },
  ui: {
    primary: "brand",
    input: {
      color: {
        white: {
          outline:
            "shadow-sm ring-1 ring-inset ring-dove-gray-300 focus:ring-2 focus:ring-dove-gray-300 focus:outline-1 focus:border-[1px] focus:border-dove-gray-300",
        },
        default: {
          outline:
            "shadow-sm ring-1 ring-inset ring-dove-gray-300 focus:border-[0.5px] focus:border-dove-gray-300 border-1",
          no_outline: "shadow-sm ring-1 ring-inset ring-dove-gray-300",
        },
      },
    },
    select: {
      color: {
        white: {
          outline:
            "shadow-sm ring-1 ring-inset ring-dove-gray-300 focus:ring-2 focus:ring-dove-gray-300 focus:outline-1 focus:border-[1px] focus:border-dove-gray-300",
        },
        default: {
          outline:
            "shadow-sm ring-inset ring-dove-gray-300 focus:border-[0.5px] focus:border-dove-gray-300 border-1",
          no_outline: "shadow-sm ring-1 ring-inset ring-dove-gray-300",
        },
      },
    },
    textarea: {
      color: {
        white: {
          outline:
            "shadow-sm ring-1 ring-inset ring-dove-gray-300 focus:ring-2 focus:ring-dove-gray-300 focus:outline-1 focus:border-[1px] focus:border-dove-gray-300",
        },
        default: {
          outline:
            "shadow-sm ring-1 ring-inset ring-dove-gray-300 focus:ring-2 focus:ring-dove-gray-300 focus:outline-1 focus:border-[1px] focus:border-dove-gray-300",
          no_outline: "shadow-sm ring-1 ring-inset ring-dove-gray-300",
        },
      },
    },
  },
});
